import { wfetch } from '../wfetch.js'
import { mergeObjects } from './mergeObjects.js'
import {
  fieldsStringToSet,
  getApiFieldInObject,
  getWfetchArguments,
} from './utilities.js'

export class DetailObjectCache {
  allFetchedFields = new Set()
  fieldCacheTime = new Map()
  fieldsWeAreCurrentlyFetching = new Set()
  lastObjectCacheTime = null
  lastObjectStringified = null
  object = {}

  // we really only store objCode and ID to remake the requestedDetailObject...
  // if it was url-based, we wouldn't need these here (see comment below)
  constructor(objCode, ID, cacheParentMethods) {
    this.objCode = objCode
    this.ID = ID
    this.entryTime = performance.now()
    this.accessCount = 0
    this.maxCacheTime = 0
    this.cacheParentMethods = cacheParentMethods
  }

  // TODO could this first argument always be url and we just pull
  // the fields off via a .split('?fields=')
  // that would align with the wfetch arguments...
  async get(fields, fetchOptions, workfrontOptions) {
    if (this.ID == null || this.objCode == null) {
      return null
    }

    this.accessCount++
    this.lastAccessedTime = performance.now()

    if (this.currentFetch) {
      await this.currentFetch
    }

    const requestedDetailObject = {
      objCode: this.objCode,
      ID: this.ID,
      fields,
    }

    const args = getWfetchArguments(
      requestedDetailObject,
      fetchOptions,
      workfrontOptions,
    )

    // we don't want to double cache, this system already does,
    // workfrontOptions shouldn't need timeToExpiration
    const { timeToExpiration: cacheTime, ...remainingWorkfrontOptions } =
      args[2]

    this.maxCacheTime = Math.max(this.maxCacheTime, cacheTime)
    this.cacheParentMethods.updateCacheTimeout(this.maxCacheTime)

    const fieldsAsSet = fieldsStringToSet(fields)

    if (this.shouldFetchFields(fieldsAsSet, cacheTime)) {
      this.currentFetch = wfetch(args[0], args[1], remainingWorkfrontOptions)
      const newObject = await this.currentFetch
      this.currentFetch = null
      this.updateObject(newObject, fieldsAsSet)
      this.updateCacheTimes(fieldsAsSet)
    }

    return this.object
  }

  updateObject(newObject, changedFieldsAsSet) {
    const lastObjectStringified = JSON.stringify(this.object)
    // if we do our own merge
    // we can keep track of the fields that actually changed
    // (see other comment below)
    const mergedObject = mergeObjects(this.object, newObject)
    const mergedObjectStringified = JSON.stringify(mergedObject)

    if (lastObjectStringified !== mergedObjectStringified) {
      const mergedSize = getByteSizeOfString(mergedObjectStringified)
      const lastSize = getByteSizeOfString(lastObjectStringified)
      this.cacheParentMethods.updateCacheSize(mergedSize - lastSize)
      this.size = mergedSize
      this.object = mergedObject
      window.dispatchEvent(
        new CustomEvent('detailObjectUpdated', {
          detail: {
            instance: this,
            // TODO should we only send the fields that were actually changed
            changedFieldsAsSet,
          },
        }),
      )
    }
  }

  // RedRock provides default fields as well
  // currently we aren't scanning the returned object for those...
  // TODO add support for that once this is more than a document object fetcher

  // what happens if something says it can cache name for an hour
  // then something else comes in and says name is only cached for 5 seconds
  // does name get a fieldCacheTime of 5 seconds or 1 hour? "globally"
  // we probably want to honor the smaller time
  updateCacheTimes(fieldsWeFetchedAsSet) {
    const previousLastObjectFetchTime = this.lastObjectCacheTime
    // could make this a utility fn so we can mock it vs mocking performance.now (for tests), getCacheTime()
    this.lastObjectCacheTime = performance.now()
    const fieldsWeDidntReFetch =
      this.allFetchedFields.difference(fieldsWeFetchedAsSet)

    for (const field of fieldsWeDidntReFetch) {
      if (!this.fieldCacheTime.has(field)) {
        this.fieldCacheTime.set(field, previousLastObjectFetchTime)
      }
    }

    for (const field of fieldsWeFetchedAsSet) {
      this.fieldCacheTime.delete(field)
    }

    this.allFetchedFields = this.allFetchedFields.union(fieldsWeFetchedAsSet)
  }

  getIsFieldCached(field, cacheTime) {
    if (getApiFieldInObject(field, this.object) === undefined) {
      return false
    }

    const lastCacheTime =
      this.fieldCacheTime.get(field) ?? this.lastObjectCacheTime

    // could make this a utility fn so we can mock it vs mocking performance.now (for tests), getCacheTime()
    return performance.now() - lastCacheTime < cacheTime
  }

  shouldFetchFields(fieldsAsSet, cacheTime) {
    if (this.object == null) {
      return true
    }

    const noFieldLevelCacheAndGlobalIsExpired =
      this.fieldCacheTime.size === 0 &&
      performance.now() - this.lastObjectCacheTime > cacheTime

    if (noFieldLevelCacheAndGlobalIsExpired) {
      return true
    }

    for (const field of fieldsAsSet) {
      if (this.getIsFieldCached(field, cacheTime)) {
        continue
      }

      return true
    }
  }
}

function getByteSizeOfString(str) {
  return new Blob([str]).size
}
