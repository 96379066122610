import { DetailObjectCache } from './DetailObjectCache.js'

const ONE_DAY = 1e3 * 60 * 60 * 24
export const DEFAULT_MAX_MEMORY_SIZE = 1e6

export class DetailObjectStore {
  store = new Map()
  timeouts = new Map()
  cacheMemorySize = 0

  constructor(maxMemorySize = DEFAULT_MAX_MEMORY_SIZE) {
    this.maxMemorySize = maxMemorySize
  }

  delete(key) {
    const item = this.store.get(key)
    this.cacheMemorySize -= item?.size || 0
    clearTimeout(this.timeouts.get(key))
    this.store.delete(key)
    this.timeouts.delete(key)
  }

  get(key) {
    return this.store.get(key)
  }
  has(key) {
    return this.store.has(key)
  }

  set(key, requestedDetailObject, cacheTime = ONE_DAY) {
    const detailObject = new DetailObjectCache(
      requestedDetailObject.objCode,
      requestedDetailObject.ID,
      {
        updateCacheSize: this.updateCacheSizeAndRecoverSpace.bind(this),
        updateCacheTimeout: (cacheTime) =>
          this.updateCacheTimeout(key, cacheTime),
      },
    )
    this.store.set(key, detailObject)

    this.updateCacheTimeout(key, cacheTime)
    return detailObject
  }

  hasSizeOverage() {
    return this.cacheMemorySize > this.maxMemorySize
  }
  // similar code exists in another cache store
  getStorageCost(item) {
    if (item.size === 0) {
      return 0
    }

    if (item.size > this.maxMemorySize) {
      return Number.POSITIVE_INFINITY
    }

    const currentTime = performance.now()
    const timeSinceLastAccess = currentTime - item.lastAccessedTime
    const timeSinceEntry = currentTime - item.entryTime

    const aboutToExpireRatio = timeSinceEntry / item.maxCacheTime
    const accessCountRatio = 1 / item.accessCount
    const lastAccessedTimeToCacheTimeRatio =
      timeSinceLastAccess / item.maxCacheTime
    const lastAccessedToEntryTimeRatioWeightedBySize =
      timeSinceLastAccess / (Math.log(item.size) * timeSinceEntry)

    return (
      item.size *
      aboutToExpireRatio *
      accessCountRatio *
      lastAccessedTimeToCacheTimeRatio *
      lastAccessedToEntryTimeRatioWeightedBySize
    )
  }

  updateCacheSizeAndRecoverSpace(size) {
    this.cacheMemorySize += size

    if (this.hasSizeOverage()) {
      const sortedBySizeCost = Array.from(this.store.values()).sort((a, b) => {
        return getStorageCost(a) > getStorageCost(b) ? 1 : -1
      })

      while (this.hasSizeOverage() && sortedBySizeCost.length) {
        this.delete(sortedBySizeCost.pop().key)
      }
    }
  }

  updateCacheTimeout(key, timeout) {
    clearTimeout(this.timeouts.get(key))
    this.timeouts.set(
      key,
      setTimeout(() => {
        this.delete(key)
      }, timeout),
    )
  }
}

const store = new DetailObjectStore()

export function getDetailObjectFromStore(requestedDetailObject) {
  const cacheKey = getCacheKey(requestedDetailObject)
  return store.get(cacheKey) ?? store.set(cacheKey, requestedDetailObject)
}

export function getCacheKey(requestedDetailObject) {
  return `${requestedDetailObject.objCode}:${requestedDetailObject.ID}`
}
