import merge from 'deepmerge'

/**
 * Taken from https://www.npmjs.com/package/deepmerge#arraymerge-example-combine-arrays
 */
function _combineMerge(target, source, options) {
  const destination = target.slice()

  source.forEach((item, index) => {
    if (typeof destination[index] === 'undefined') {
      destination[index] = options.cloneUnlessOtherwiseSpecified(item, options)
    } else if (options.isMergeableObject(item)) {
      destination[index] = merge(target[index], item, options)
    } else if (target.indexOf(item) === -1) {
      destination.push(item)
    }
  })
  return destination
}

/**
 * Works similar to lodash.merge, but does not mutate target.
 * @param target
 * @param source
 */
export function mergeObjects(target, source) {
  return merge(target, source, { arrayMerge: _combineMerge })
}
