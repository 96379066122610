/** @type {string} */
export const CONTENT_TYPE_FORM = 'application/x-www-form-urlencoded'
/** @type {string} */
export const CONTENT_TYPE_JSON = 'application/json'
export const GET = 'GET'
export const POST = 'POST'
export const PUT = 'PUT'
export const URI_FIELD_FORM_ENCODING_FIX_REGEX = /uri%5B%5D/g

export const XSRF_MISMATCH = 'XSRF Mismatch'
