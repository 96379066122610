import { defaultWorkfrontOptions } from './constants.js'
import { getDefaultFieldsForObjCode } from './getDefaultFields.js'

export function normalizeRequestedDetailObject({ fields, objCode, objID, ID }) {
  let requestedFields = fields

  if (fields === undefined || fields.length === 0) {
    requestedFields = getDefaultFieldsForObjCode(objCode)
  } else if (Array.isArray(fields)) {
    requestedFields = fields.join(',')
  }

  return {
    objCode,
    ID: objID ?? ID,
    fields: requestedFields,
  }
}

export function fieldsStringToSet(fieldsString) {
  return new Set(fieldsString.split(','))
}

export function getWfetchArguments(
  requestedDetailObject,
  fetchOptions,
  workfrontOptions,
) {
  try {
    return [
      getDetailObjectUrl(requestedDetailObject),
      fetchOptions ?? {},
      ensureWorkfrontOptionsHasCacheDefaults(workfrontOptions),
    ]
  } catch (e) {
    console.log('problem constructing fieldsSet')
    console.log(e)
  }
}

export function ensureWorkfrontOptionsHasCacheDefaults(
  workfrontOptions = defaultWorkfrontOptions,
) {
  return workfrontOptions.timeToExpiration === undefined
    ? {
        ...workfrontOptions,
        timeToExpiration: defaultWorkfrontOptions.timeToExpiration,
      }
    : workfrontOptions
}

export function getDetailObjectUrl(requestedDetailObject) {
  const { objCode, ID, fields } = requestedDetailObject
  const fieldsQueryParam = fields.length ? `?fields=${fields}` : ''

  return `/attask/api-internal/${objCode}/${ID}${fieldsQueryParam}`
}

export function getApiFieldInObject(field, baseObject) {
  let currentBase = baseObject

  // instead of dot notation, RedRock fields use :
  for (const fragment of field.split(':')) {
    if (fragment === '*') {
      // Do nothing the * doesn't mean anything to us when parsing objects in JS but it matters to the API
      continue
    }

    currentBase = currentBase?.[fragment]

    // collections are returned as arrays,
    // an empty collection is an empty array
    // we aren't guaranteed to have any values in a collection even if it is "fetched"
    if (Array.isArray(currentBase)) {
      break
    }

    if (currentBase === undefined) {
      return undefined
    }
  }

  return currentBase
}
