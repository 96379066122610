import { readCookie } from 'workfront-cookie'

import {
  CONTENT_TYPE_FORM,
  CONTENT_TYPE_JSON,
  XSRF_MISMATCH,
} from './constants.js'

const UNSUPPORTED_CHARACTERS = ['../', '#']

export function errorOutIfUrlContainsUnsupportedCharacters(url) {
  if (window.config?.wfetchErrorOnPathHopping !== true) {
    return
  }

  for (const character of UNSUPPORTED_CHARACTERS) {
    if (url.includes(character)) {
      throw new Error(
        `The URL "${url}" contains an unsupported character(s), "${character}"`,
      )
    }
  }
}

/**
 * If you are using an AbortController you can use this to check if your request errored out due to the request being aborted.
 * @param {any} err - usually a DOMException with AbortError name but can be anything
 * @returns {boolean}  boolean if err is an AbortError or not
 */
export function isAbortError(err) {
  return err instanceof DOMException && err.name === 'AbortError'
}

export const unwrapResponse = async (response) => {
  const contentType = response.headers.get('content-type')
  const data = (await isJSON(contentType)) ? response.json() : response.text()

  return data
}

const isJSON = (contentType) => contentType?.includes(CONTENT_TYPE_JSON)
const isForm = (contentType) => contentType?.includes(CONTENT_TYPE_FORM)
const getContentType = (headers) =>
  headers['Content-Type'] || headers['content-type']

export const hasFormHeader = (headers) => isForm(getContentType(headers))
export const hasJSONHeader = (headers) => isJSON(getContentType(headers))

/**
 * Utility method to get more data back on a non-ok response. Use as the value of handleErrorResponse in a wfetch call.
 * @param {object} response - a fetch response object, can have request type json or text
 * @returns {Promise<never>}  rejected promise with error data
 */
export async function rejectResponseWithErrorData(response) {
  const errorData = await unwrapResponse(response)

  return Promise.reject({ errorData, response })
}

export function getTimeOnPage() {
  return Math.round(performance.now())
}

export function getEimInstanceId() {
  return window.adobeMetrics?.metricsState?.instanceId ?? 'UNKNOWN'
}

export function addParamToUrl(url, param, value) {
  const [pathname, search] = url.split('?')
  const searchParams = new URLSearchParams(search)
  if (searchParams.has(param)) {
    searchParams.delete(param)
  }
  searchParams.append(param, value)
  return `${pathname}?${searchParams.toString()}`
}

export function getUrlWithOptionalPublicToken(url) {
  const publicToken = window.config?.publicToken

  if (publicToken) {
    return addParamToUrl(url, 'publicToken', publicToken)
  }

  return url
}

export async function getSessionExpirationTime(isUnifiedShellEnabled) {
  if (isUnifiedShellEnabled) {
    try {
      const { default: user } = await import('@adobe/exc-app/user')
      const imsToken = await user.get('imsToken')
      const { created_at, expires_in } = JSON.parse(
        atob(imsToken.split('.')[1]),
      )

      return Number.parseInt(created_at, 10) + Number.parseInt(expires_in, 10)
    } catch (e) {}
  } else {
    const cookieValue = readCookie('sessionExpiration')
    if (cookieValue) {
      return Number.parseInt(cookieValue, 10)
    }
  }
}

// TODO once this is no longer under a toggle it can be synchronous
export async function shouldRetry(response) {
  if (response.status === 422) {
    try {
      const clonedResponse = response.clone()
      const {
        error: { message },
      } = await clonedResponse.json()

      if (message === XSRF_MISMATCH) {
        const { getTreatmentIsEnabled } = await import('@wf-mfe/toggles')

        const enabled = await getTreatmentIsEnabled('xsrf-422')
        return enabled
      }
    } catch (e) {
      console.error(e)
    }
  }
}
