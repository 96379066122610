import { useEffect, useState } from 'react'

import { getDetailObjectFromStore } from './DetailObjectStore.js'
import {
  fieldsStringToSet,
  normalizeRequestedDetailObject,
} from './utilities.js'

// IDEA provide a refetch/refresh function (maybe only on the hook usage)

export async function getDetailObject(requestedDetailObject, cacheTime) {
  return wfetchDetailObject(
    requestedDetailObject,
    {},
    { timeToExpiration: cacheTime },
  )
}

export async function wfetchDetailObject(
  requestedDetailObject,
  fetchOptions,
  workfrontOptions,
) {
  const normalizedRequestedDetailObject = normalizeRequestedDetailObject(
    requestedDetailObject,
  )
  return getDetailObjectFromStore(normalizedRequestedDetailObject).get(
    normalizedRequestedDetailObject.fields,
    fetchOptions,
    workfrontOptions,
  )
}

export function useDetailObject(requestedDetailObject, cacheTime) {
  const normalizedRequestedDetailObject = normalizeRequestedDetailObject(
    requestedDetailObject,
  )
  const detailObject = getDetailObjectFromStore(requestedDetailObject)
  const { fields } = normalizedRequestedDetailObject

  const [detailObjectData, setDetailObjectData] = useState()

  useEffect(() => {
    const controller = new AbortController()

    // TODO it'd be nice to just reuse getDetailObject here
    // ...but we need detailObject and fields already for the comparisons below
    // and that is half of what the that other fn does
    detailObject
      .get(fields, {}, { timeToExpiration: cacheTime })
      .then((obj) => {
        setDetailObjectData(obj)

        if (obj) {
          window.addEventListener(
            'detailObjectUpdated',
            ({ detail: { instance, changedFieldsAsSet } }) => {
              if (instance === detailObject) {
                const fieldsAsSet = fieldsStringToSet(fields)
                const aRequestedFieldChanged =
                  fieldsAsSet.has('*') ||
                  fieldsAsSet.intersection(changedFieldsAsSet).size > 0

                if (aRequestedFieldChanged) {
                  setDetailObjectData(detailObject.object)
                }
              }
            },
            { signal: controller.signal },
          )
        }
      })

    return () => controller.abort()
  }, [detailObject, fields, cacheTime])

  return detailObjectData
}

// should this just use useWFetch and update the store value?
export function useDetailObjectWfetch(
  requestedDetailObject,
  fetchOptions,
  workfrontOptions,
) {
  const normalizedRequestedDetailObject = normalizeRequestedDetailObject(
    requestedDetailObject,
  )
  const [data, setData] = useState(null)
  const detailObject = getDetailObjectFromStore(normalizedRequestedDetailObject)
  const { fields } = normalizedRequestedDetailObject

  useEffect(() => {
    const fetchDetailObject = async () => {
      const result = await detailObject.get(
        fields,
        fetchOptions,
        workfrontOptions,
      )
      setData(result)
    }

    // this is dirty
    if (data === null) {
      fetchDetailObject()
    }
    // these were causing an inifnite loop without the above if check
    // so these aren't running if detailObjectDefinition fields changes
    // see how we handle it in useWFetch
  }, [detailObject, fields, fetchOptions, workfrontOptions, data])

  return { data }
}

export function useDetailObjectField() {}
