export function getDefaultFieldsForObjCode(objCode) {
  switch (objCode) {
    case 'TASK':
      return [
        'projectID',
        'project:currency',
        'project:name',
        'permissions',
      ].join(',')
    case 'DOCU':
      return DEFAULT_DOCUMENT_FIELDS
  }

  return ''
}

const VERSION_FIELDS = [
  'ID',
  'docSize',
  'documentID',
  'documentProvider:name',
  'documentProviderID',
  'documentTypeLabel',
  'enteredBy',
  'entryDate',
  'ext',
  'externalIntegrationType',
  'externalStorageID',
  'fileName',
  'fileType',
  'icon',
  'version',
  // Proof fields
  'isMultiFileProof',
  'isProofable',
  'proofID',
  'proofStatus',
  'proofStatusDate',
  'proofStatusMsgKey',
  'proofImportStatus',
  'proofToken',
]

const DOCUMENT_FIELDS = [
  'name',
  'description',
  // if uncommented, everything should be equal
  // 'lastUpdateDate',
  'referenceNumber',
  'checkedOutByID',
  'checkOutTimestamp',
  'permissions',
  'referenceObjCode',
  'referenceObjID',
  'downloadURL',
  'folders:linkedFolderID',
  'versions',
  'approvals:status',
  'approvals:approver:isActive',
  'currentVersionID',
  'currentVersion:externalIntegrationType',
  'currentVersion:documentProviderID',
  'currentVersion:externalStorageID',
]

const DEFAULT_DOCUMENT_FIELDS = DOCUMENT_FIELDS.concat(
  VERSION_FIELDS.map((field) => `versions:${field}`),
).join(',')
